import { LoggedInUser } from 'src/app/models/logged-in-user.model';

export class UserLoggedIn {
  public static type: string = '[User] User Logged In';

  constructor(public readonly user: LoggedInUser) {}
}

export class UserLoggedOut {
  public static type: string = '[User] User Logged Out';

  constructor() {}
}
